/* @media screen and (min-width: 601px) { */
div.Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #D3D3D3;
    margin-top: 10px;
    position: relative;

    div.MidRightElements{
        display: flex;
        flex-direction: column;
        div.ItemRightElements{
            display: flex;
            margin-bottom: 10px;
            /* border: 1px solid red; */
            img.ItemImage{
                max-width:  90px !important;
                min-width: 90px !important;
                max-height: 60px !important;
                min-height: 60px !important;
            }

            div.ItemFlags {
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
            }
                
            div.iFlags {
                color: var(--mainWhite);
                width: 100%;
                max-width: 200px;
                border-radius: var(--brdRadius);
                height: 16px;
                font-size: 11px;
                font-weight: 500;
                
                &.iNew {
                    border:1px solid  #FF8000;
                    background-color: #FF8000;
                }
                
                &.iSale {
                    border:1px solid  #E10000;
                    background-color: #E10000;
                }
                
                &.iSeason {
                    border:1px solid  #FF8000;
                    background-color: #FF8000;
                }
            }
                
            div.UserCommentForm{
                padding-top: 10px;
                display: flex;
                /* flex-direction: column; */
                
                font-size: 10px;
                text-decoration: underline;

                div.Label{
                    
                }

                div.Label:hover{
                    cursor: pointer;
                }

                div.Input{
                    input{
                        text-decoration: underline;
                        font-size: 10px;
                        width: 5vw;
                        border: unset;
                        outline: none;
                        outline-bottom: 2px;
                    }
                }
            }

                div.ItemDetails{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 47px;
                    /* border: 1px solid blue; */
                    margin-right: 25px;
                
                div.ItemName{
                    font-size: 13px;
                    font-weight: 500;
                    color: red;
                    max-width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;  
                }

                div.ItemName:hover{
                    cursor: context-menu !important;
                }

                div.ItemQuantity{
                    font-size: 13px;
                    font-weight: 700;
                    text-align: right;
                }
                div.ItemQuantity:hover{
                    cursor: context-menu !important;
                }
            }
        }

        div.Comment{
            /* border: 1px solid blue; */
            display: flex;
            align-items: center;
            gap: 1px;
            width: 100%;
            /* border: 1px solid red; */

            div.Title{
                font-size: 13px;
                text-decoration: underline;
            }

            div.Title:hover{
                cursor: pointer;
            }

            div.InputText{
                
                input{
                    font-size: 13px;
                    outline: none;
                    border: none;
                    /* text-decoration: underline; */
                }
            }
        }
    }

    div.ItemLeftElements{
        /* border: 1px solid black; */
        display: flex;
        align-items: center;
        position: relative;

        div.ItemPrice{
            font-weight: 500;
            margin-left: 20px
        }

        div.ItemPrice:hover{
            cursor: context-menu !important;
        }

        div.ItemBtnsAndTooltip{
            margin: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 1vh;

            div.MoreInfoTooltip{
                position: absolute;
                flex: 1;
                top: -17px;
                left: 0;
                font-size: 10px;
                text-decoration: underline;
                color: #B30C00;
                
                .Tooltip{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                    /* word-break: break-all !important; */
                    /* overflow-wrap: break-word !important; */
                    /* display: block; */
                    /* overflow: hidden; */
                    /* text-overflow: string; */
                    /* word-wrap: break-word !important; */
                    /* word-break: break-all !important; */
                    /* overflow-wrap: break-word !important; */
                }
            
                /* span.MoreInfoTooltipText{
                    visibility: hidden;
                } */
            }
            div.MoreInfoTooltip:hover{
                cursor: help;
                color: #EB0028;

            }
            
            button{
                color: #DC143C;
                border-radius: 10px;
                width: 100px;
                background-color: #FFFFFF;
                border: 1px solid #DC143C;
                outline: none;
            }
            button:hover{
                background-color: #EB0028;
                color: #FFFFFF;
                cursor: pointer;
            }
            div.EditButton{
                /* margin-bottom: 5px */
            }
            div.RemoveButton{
                /* margin-bottom: 5px */
            }
        }
    }
}
/* } */

@media (min-resolution: 140dpi){
    div.Container{
        justify-content: center;
        div.ItemLeftElements{
            div.ItemPrice{
                font-weight: 500;
                font-size: 13px;
                /* margin-left: 20px */
            }
        }
    }
}

@media (max-width: 600px){
    div.Container{
        div.ItemLeftElements{
            div.ItemPrice{
                margin-left: 5px
            }
            div.ItemBtnsAndTooltip{
                button{
                    font-size: 11px;
                    color: #DC143C;
                    border-radius: 10px;
                    width: 65px;
                    background-color: #FFFFFF;
                    border: 1px solid #DC143C;
                    outline: none;
                }
            }
        }
        div.MidRightElements{
            max-width: 50;
            min-width: 50vw;
            overflow: visable;
            div.ItemRightElements{
                margin: 0;
                padding: 0;

                div.UserCommentForm{
                    div.Input{
                        input{
                            text-decoration: underline;
                            font-size: 10px;
                            width: 10px !important;
                            border: unset;
                            outline: none;
                            outline-bottom: 2px;
                        }
                    }
                }
            }
        }
    }
}