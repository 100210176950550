/* @media screen and (min-width: 601px) { */
div.Container{
  /* border: 2px solid red; */
  justify-self: right;
  /* flex-flow: column nowrap; */
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  /* max-width: 40vw; */
  /* margin-bottom: 50px; */
  margin: 5px 5px 0 5px;
  padding: 5px 5px 0 5px;
  white-space: nowrap;
  border-radius: var(--brdRadius);
  background-color: var(--mainWhite);
  overflow: hidden;
  position: relative;
  height: 77vh;
  max-height: 77vh;
  /* min-width: 31vw; */
  min-width: 34vw;

  div.TopDiv {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    height: 100%;
    padding: 10px;

    div.Summary {
      display: flex;

      /* flex-flow: row-reverse wrap; */
      flex-flow: row-reverse;
      align-self: center;
      align-content: center;
      justify-content: space-between;
      border-radius: 50px;
      height: 50px;
      width: 340px;
      max-width: 100%;
      border-width: 1px;
      border-style: solid;

      .CartSum {
        align-self: center;
        padding-top: 6px;

        .CartSumSh {
          font-size: 16px;
        }

        .CartSumVal {
          font-size: 26px;
        }
      }

      .CartText {
        align-self: center;
        text-align: right;
        margin-right: 15px;
        font-size: 16px;
        line-height: 18px;
      }

      .CartWrapper {
        display: flex;
        height: 100%;

        .CartImg {
          width: 26px;
          height: 26px;
          margin: 11px;
          margin-left: 15px;
          /* height: 80px; */
        }

        .ItemsInCart {
          color: var(--mainWhite);
          position: relative;
          top: 17px;
          height: 21px;
          line-height: 21px;
          min-width: 21px;
          left: -23px;
          font-size: 14px;
          border-radius: var(--brdRadius);
          padding-left: 3px;
          padding-right: 3px;
          font-weight: 600;
        }
      }
    }

    div.CartItems {
      /* border: 2px solid red; */
      margin-top: 20px;
      overflow: auto;
      max-height: 60%;
      padding: 0 20px 0 20px;
      width: 100%;

      display: flex;
      flex-direction: column-reverse;

      .CartItemsEmpty {
        margin-top: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* gap: 6px; */
        color: var(--mainColor);
        top: 15vh;
        
        span:nth-child(1) {
          margin-bottom: 6px;
          color: #00b1e1;
          font-size: 27px;
          font-weight: 600;
        }
        span:nth-child(2) {
          margin-bottom: 6px;
          color: #53cbeb;
          font-size: 25px;
        }
        
        img.BasketImg {
          width: 80px;
          /* height: 80px; */
        }
      }
    }
  }
  
  div.PaymentButtons{
    position: absolute;
    width: 100%;
    bottom: 15px;
  }
}