div.Inputs{
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 0.6vw; */
    direction: ltr;
    
    input{
        text-align: center;
        /* width: 3.5vw; */
        /* margin-right: 0.6vw; */
        outline: none;
        border: none;
        border-bottom: 2px solid #cacaca;
        font-size: 27px;
        font-weight: 500;
        color: rgb(68,0,0,0.68);
    }
}
