div.ModalContainer{
    /* margin: 0;
    padding: 0;
    background: url(../../../assets/welcome_bg.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
    background-color: #FFFF;
    min-height: 100%; */



    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 18px; */

    h3{
        text-align: center;
        width: 80%;
    }

    button{
        margin-top: 18px;
        outline: none;
        border: none;
        width: 155px;
        height: 33px;
        border: 1px solid #00b1e1;
        border-radius: 20px;
        font-size: 16px;
        align-text: center;
        font-weight: 600;
        color: #00b1e1;
        background-color: unset;
    }

    button:hover{
        cursor: pointer;
    }

    h3{
        text-overflow: ellipsis;
    }
}