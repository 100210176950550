/* @media screen and (min-width: 601px) { */
    div.Container{
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        overflow: auto;
        overflow-x: hidden;

        div.CloseButton {
            button{
                position: absolute;
                width: 40px;
                font-size: 25px;
                font-weight: 900;
                left: 6px;
                top: 7px;
                background-color: unset;
                color: #520E00;
                cursor: pointer;
                border: none;
                outline: none;
            }

            /* button:hover{
                animation-name: spin;
                animation-duration: 1500ms;
                animation-iteration-count: 1.07;
                animation-timing-function: ease-in-out; 
            }

            @keyframes spin {
                from {
                    transform:rotate(0deg);
                }
                to {
                    transform:rotate(360deg);
                }
            } */
        }

        div.StepDescription{
            margin-top: 20px;
            font-size: 20px;
            font-weight: 700;
            color: #270001;
        }
        
        div.ItemNumber{
            /* margin-top: 10px; */
            font-size: 20px;
            font-weight: 630;
            color: #270001;
        }

        
        div.StepsBar{
            margin: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-family: Consolas;
            font-weight: 530;
            color: #dcdcdc;
            width: 100%;
            word-wrap: break-word;
            div.StepBar{
                /* color: #dcdcdc; */
                width: 1.1rem;
                height: 100%;
                /* border: 1px solid #dcdcdc; */
                border-radius: var(--brdRadius);
            }
            
        }

        div.LevelDescription{
            font-size: 20px;
            margin-top: 15px;
            margin-bottom: 15px;
            font-weight: 600;
        }

        div.Buttons{
            display: flex;
            margin-top: 25px;
            margin-bottom: 15px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* gap: 8px; */

            button{
                color: #DC143C;
                border-radius: 7px;
                width: 70px;
                background-color: unset;
                border: 1px solid #DC143C;
                outline: none;
                /* margin-bottom: 25px; */
            }
            
            button:hover{
                background-color: rgba(255, 0, 0, 0.05);
                cursor: pointer;
            }

        }

        div.Buttons > * + * {
            margin-top: 8px;
        }
    }
/* } */