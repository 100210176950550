/* @media screen and (min-width: 601px) { */
    div.Container{
        display: flex;
        /* border: 1px solid #e6e6e6; */
        border-radius: 20px;
        margin: 20px;
        padding: 10px;
        padding-top: 10px;

        div.RightElements{
            /* border: 1px solid blue; */
            width: 30%;

            div.Checked{
                display: flex;
                justify-content: flex-start;
            }
        }
        div.MidElements{
            /* border: 1px solid green; */
            width: 55%;
            display: flex;
            flex-direction: column;
            position: relative;

            div.ItemName{
                position: absolute;
                top: 0;
                font-size: 15px;
                font-weight: 500;
                color: red;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 200px;
                text-align: right;
            }
    
            div.ItemButtons{
                display: flex;
                position: absolute;
                bottom: 0;
                /* gap: 10px; */

                div.ItemUnitsBtn{
                    color: #DC143C;
                    border-radius: 10px;
                    width: 60px;
                    background-color: #FFFFFF;
                    border: 1px solid #DC143C;
                    outline: none;
                }

                div.ItemAddBtn{
                    color: #DC143C;
                    border-radius: 10px;
                    width: 85px;
                    background-color: #FFFFFF;
                    border: 1px solid #DC143C;
                    outline: none;
                }
                div.ItemAddBtn:hover{
                    background-color: #EB0028;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }

            div.ItemButtons > * + * {
                margin-right: 10px;
            }
        }
        div.LeftElements{
            width: 15%;

            div.MoreInfoTooltip{
                font-size: 14px;
                text-decoration: underline;
                color: #B30C00;

                .Tooltip{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }
            }
            div.MoreInfoTooltip:hover{
                cursor: help;
                color: #EB0028;
            }
        }
    }
    div.Container:hover{
        background-color: rgba(244, 230, 230, 0.16);
    }
/* } */

@media (max-width: 550px){
    div.Container{
        div.MidElements{
            div.ItemName{
                font-size: 12px;
            }
        }
        
        div.LeftElements{
            div.MoreInfoTooltip{
                font-size: 10px;
            }
        }
    }
}