/* @media (-webkit-min-device-pixel-ratio: 1) { */
div.Container{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    border: 4px solid #F6F2F2;
    margin-top: 10px;
    border-radius: 15px;
    overflow: auto;

    div.TopContent{
        /* border: 4px solid green; */
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        div.CloseButton {
            button{
                /* position: absolute; */
                /* width: 40px; */
                font-size: 25px;
                font-weight: 600;
                /* left: 6px; */
                /* top: 7px; */
                background-color: unset;
                color: #520E00;
                cursor: pointer;
                border: none;
                outline: none;
            }
        }

        div.ItemName{
            cursor: default;
            padding-right: 25px; 
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 

            flex: 1;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }
    }

    /* div.BodyContent{ */
        form{
            /* border: 4px solid red; */
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100% !important;
            max-height: 100%;
    
            div.FormButtons{
                /* border: 4px solid blue; */
                /* height: min-content; */
    
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                div.NextAndSubmitBtns{
                    display: flex;
                    flex-direction: column;
                    /* gap: 5px; */
                }

                div.NextAndSubmitBtns > * + *{
                    margin-top: 5px;
                }
                
                button{
                    color: #DC143C;
                    border-radius: 20px;
                    width: 280px;
                    max-width: 100%;
                    height: 25px;
                    background-color: unset;
                    border: 1px solid #DC143C;
                    outline: none;
                }
                
                button:hover{
                    background-color: rgba(255, 0, 0, 0.05);
                    cursor: pointer;
                }
            }
        }
    /* } */
}

@media (max-resolution: 110dpi){
    div.Container{
        div.TopContent{
            div.ItemName{
                font-size:25px;
            }
        }
    }
}

@media (min-resolution: 130dpi){
    div.Container{
        div.TopContent{
            div.ItemName{
                font-size:15px;
            }
        }
    }
}

@media (min-resolution: 150dpi){
    div.Container{
        div.TopContent{
            div.ItemName{
                font-size:13px;
            }
        }
    }
}