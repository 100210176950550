div.Container{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 65vh;
    width: 400px;
    /* min-height: 100%; */
    position: relative;

    div.UserChoosing{
        div.OptionBtns{
            display: flex;
            justify-content: center;
            /* gap: 30px; */
    
            button{
                width: 100px;
                height: 25px;
                outline: none;
                color: #FFFF;
                border: none;
                border-radius: 3px;
                margin: 0 7px 0 7px;
            }
    
            button:hover{
                cursor: pointer;
            }
        }

        
        div.InputFields form{
            /* width: 100vw; */
            display: flex;
            /* justify-content: center;
            text-align: center; */
            flex-direction: column;
            /* gap: 5px; */
            margin-top: 10px;
            align-items: center;
            /* position: relative; */
            

            input{
                display: inline-block;
                width: 300px;
                height: 25px;
                border:none;
                outline: none;
                border-radius: 3px;
                resize: none;
                overflow: auto;
                font-family: arial;
            }

            input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                padding-right: 0.3rem;
                color: #adabab;
                opacity: 1; /* Firefox */
            }
              
            input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
                padding-right: 0.3rem;
                color: #adabab;
            }
              
            input::-ms-input-placeholder{ /* Microsoft Edge */
                padding-right: 0.3rem;
                color: #adabab;
            }

            div.StreetField{
                position: relative;
                display: flex;
                align-items: center;

                div.LeftElement{
                    position: absolute;
                    left: 10px;
                    
                    label{
                        font-size: 12px;
                        color: #adabab;
                    }
                    label:hover{
                        cursor: pointer;
                    }
                    /* label:nth-child(1){
                        margin-left: 5px;
                    } */
                    label:nth-child(1){
                        font-weight: 550;
                        text-decoration: underline;
                        color: #000000;
                    }
                }

                
                input{
                    display: inline-block;
                    width: 300px;
                    height: 25px;
                    border:none;
                    outline: none;
                    border-radius: 3px;
                    resize: none;
                    font-family: arial;
                }
    
                input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                    padding-right: 0.3rem;
                    color: #adabab;
                    opacity: 1; /* Firefox */
                }
                  
                input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
                    padding-right: 0.3rem;
                    color: #adabab;
                }
                  
                input::-ms-input-placeholder{ /* Microsoft Edge */
                    padding-right: 0.3rem;
                    color: #adabab;
                }
            }

            div.TowInputInRow{
                display: flex;
                width: 300px;
                
                input:nth-child(1){
                    margin-left: 6px;
                }
                /* gap: 6px; */
            }
            
            div.CheckTerm{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                input{
                    width: 12px;
                }
                span{
                    font-size: 8.5px;
                    font-weight: 600;
                    color: rgb(255,255,255,0.35)
                }

                .TextTermBtn{
                    font-weight: 900;
                    text-decoration: underline;
                    color: rgb(255,255,255,0.65);
                }
                
                .TextTermBtn:hover{
                    cursor: pointer;
                }
            }

            div.BottomBtns{
                /* margin-top: 120px; */
                display: flex;
                flex-direction: column;
                /* gap: 15px;   */
                position: absolute;
                bottom: 0;

                button:nth-child(1){
                    background-color: #FF0000;
                    width: 350px;
                    height: 35px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFFF;
                    font-size: 18px;
                    font-weight: 530;
                }
                button:nth-child(2){
                    margin-top: 15px;
                    background-color: unset;
                    width: 350px;
                    height: 35px;
                    border-radius: 30px;
                    outline: none;
                    border: 2px solid rgb(255, 255, 255, 0.3);
                    color: #FFFF;
                    font-size: 18px;
                    font-weight: 530;
                }
                
                button:hover{
                    cursor: pointer;
                }
            }
        }

        div.InputFields form > *{
            margin-bottom: 5px;
        }
    }
}

@media (max-width: 850px){
    div.Container {
        width: 300px;
        padding-top: 10px;
        div.UserChoosing{
            div.InputFields form{
                display: flex;
                flex-direction: column;
                /* gap: 5px; */
                margin-top: 10px;
                align-items: center;

                input{
                    width: 250px;
                    height: 25px;
                }
    
                div.StreetField{
                    position: relative;
                    display: flex;
                    align-items: center;
    
                    div.LeftElement{
                        position: absolute;
                        left: 10px;
                        
                        label{
                            font-size: 12px;
                            color: #adabab;
                        }
                        label:hover{
                            cursor: pointer;
                        }
                        /* label:nth-child(1){
                            margin-left: 5px;
                        } */
                        label:nth-child(1){
                            font-weight: 550;
                            text-decoration: underline;
                            color: #000000;
                        }
                    }

                    input{
                        width: 250px;
                        height: 25px;
                    }
                }
    
                div.TowInputInRow{
                    width: 250px;
                }

                div.CheckTerm{
                    width: 250px;
                    span{
                        font-size: 6.5px;
                    }
                }

                div.BottomBtns{
                    /* gap: 10px;   */
    
                    button:nth-child(1){
                        width: 250px;
                        height: 30px;
                    }
                    button:nth-child(2){
                        margin-top: 10px;
                        width: 250px;
                        height: 30px;
                    }
                }
            }
        }
    }
}