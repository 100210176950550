div.Container{
    text-align: center;
    margin: 0;
    padding: 0;

    div.CancleXBtn{
        text-align: left;
        
        span{
            font-size: 22px;
        }

        span:hover{
            cursor: pointer;
        }
    }
    
    div.TextContnet{
        div.Title{
            span{
                font-weight: 550;
                font-size: 23px;
            }
        }

        div.TowRowText{
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 16px;
            font-weight: 500;
        }

        div.Price{
            span:nth-child(1){
                font-weight: 650;
                font-size: 20px;
            }
            span:nth-child(2){
                font-weight: 650;
                font-size: 30px;
            }
        }
    }
    
    div.ContinueBtn{
        display: flex;
        justify-content: center;
        margin-top: 17px;

        span{
            border: 1px solid #808080;
            border-radius: 20px;
            width: 55%;
        }

        span:hover{
            cursor: pointer;
        }
    }
}