div.Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    position: relative;

    div.OptionBtns{
        display: flex;
        justify-content: center;
        gap: 30px;

        button{
            width: 100px;
            height: 25px;
            outline: none;
            background-color: red;
            color: #FFFF;
            border: none;
            border-radius: 3px;
        }

        button:hover{
            cursor: pointer;
        }
    }
    
    .TitleText{
        text-align: center;
        color: #FFFF;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 20px;
    }

    div.InputFields form{
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        /* gap: 6px; */
        /* margin-top: 10px; */
        align-items: center;
        height: 300px;
        /* width: 100vw; */

        input{
            display: inline-block;
            width: 300px;
            height: 25px;
            border:none;
            outline: none;
            /* border-width: 0px; */
            border-radius: 3px;
            resize: none;
            overflow: auto;
            font-family: arial;
        }

        input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
            padding-right: 0.3rem;
            color: #adabab;
            opacity: 1; /* Firefox */
        }
            
        input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
            padding-right: 0.3rem;
            color: #adabab;
        }
            
        input::-ms-input-placeholder{ /* Microsoft Edge */
            padding-right: 0.3rem;
            color: #adabab;
        }

        div.CheckTerm{
            /* width: 100%; */
            /* height: 3vh; */
            /* border: 1px solid blue; */
            display: flex;
            align-items: center;
            justify-content: center;
            input{
                width: 12px;
            }
            span{
                font-size: 8.5px;
                font-weight: 600;
                color: rgb(255,255,255,0.35)
            }

            .TextTermBtn{
                font-weight: 900;
                text-decoration: underline;
                color: rgb(255,255,255,0.65);
            }
            
            .TextTermBtn:hover{
                cursor: pointer;
            }
        }
        
        .Btns{
            /* margin-top: 20px; */
            display: flex;
            flex-direction: column;
            /* gap: 15px; */

            position: absolute;
            bottom: 0;

            button{
                width: 350px;
                height: 35px;
                border-radius: 30px;
                outline: none;
                border:none;
                color: #FFFF;
                font-size: 18px;
            }

            button:nth-child(3){
                background-color: unset;
                width: 350px;
                height: 35px;
                border-radius: 30px;
                outline: none;
                border: 2px solid rgb(255, 255, 255, 0.3);
                color: #FFFF;
                font-size: 18px;
                font-weight: 530;
            }
            
            button:hover{
                cursor: pointer;
            }
        }
        .Btns > * + *{
            margin-top: 15px;
        }
    }

    div.InputFields form > * + *{
        margin-top: 6px;
    }
}

@media (max-width: 850px){
    div.Container{
        .TitleText{
            padding-top: 15px;
            padding-bottom: 15px;
            margin-bottom: 0;
            font-size: 10px;
        }
        div.InputFields form{
            input{
                width: 230px;
            }
            div.CheckTerm{
                span{
                    font-size: 6.5px;
                }
            }
    
            .Btns{
                /* gap: 10px; */
                button{
                    width: 250px;
                    height: 30px;
                }
    
                button:nth-child(3){
                    width: 250px;
                    height: 30px;
                }
            }
            .Btns > * + *{
                margin-top: 10px;
            }
        }
    }
}