/* @media screen and (min-width: 601px) { */
div.Container{
    display: flex;
    justify-content: center;
    position: relative;

    div.PaymentBtns{
        margin: 0;
        display: flex;
        flex-direction: column;
        width: 60%;
        /* gap: 1vh; */
        
        div{
            font-size: 11px;
            font-weight: 400;
            border-radius: 20px;
            /* height: 23px ; */
            padding-top: 5px;
            padding-bottom: 5px;
            /* padding: 5px; */
        }

        div:hover{
            cursor: pointer;
        }

        div:nth-child(1){
            span{
                font-weight: 700;
                text-decoration: underline;
                background-color: unset;
            }
        }

        div:nth-child(2){
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 15px;
            font-weight: 400;
            color: #FFFFFF;
        
        }
        div:nth-child(3):hover {
            cursor: default;
        }
    }

    div.PaymentBtns > * + *{
        margin-top: 1vh;
    }
}
/* } */

@media (min-resolution: 140dpi){
    div.Container{
        div.PaymentBtns{
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 55%;
            height: 50%;
            /* gap: 1vh; */
            
            div{
                font-size: 8px;
                font-weight: 400;
                border-radius: 20px;
                padding-top: 1px;
                padding-bottom: 1px;
            }
        }

        div.PaymentBtns > * + *{
            margin-top: 1vh;
        }
    }
}