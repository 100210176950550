div.Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 65vh;
    /* min-height: 100%; */

    div.Title{
        margin: 15px;
        color: #FFFF;
        font-size: 25px;
    }

    div.UserChoosing{
        div.OptionBtns{
            display: flex;
            justify-content: center;
            /* gap: 30px; */
            margin-right: 15px;
            button{
                margin-left: 15px;
                width: 100px;
                height: 25px;
                outline: none;
                /* background-color: red; */
                color: #FFFF;
                border: none;
                border-radius: 3px;
            }
    
            button:hover{
                cursor: pointer;
            }
        }
        
        form{
            display: flex;
            flex-direction: column;
            /* gap: 6px; */
            margin-top: 10px;
            align-items: center;
            height: 280px;

            input, select, textarea{
                display: inline-block;
                width: 300px;
                height: 25px;
                border:none;
                outline: none;
                border-radius: 3px;
                resize: none;
                overflow: auto;
                font-family: arial;
                margin-bottom: 6px;
            }

            textarea{
                height: 80px;
                margin-bottom: 6px;
            }

            input::placeholder, textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                padding-right: 0.3rem;
                color: #adabab;
                opacity: 1; /* Firefox */
              }
              
            input:-ms-input-placeholder, textarea::placeholder { /* Internet Explorer 10-11 */
                padding-right: 0.3rem;
                color: #adabab;
              }
              
            input::-ms-input-placeholder, textarea::placeholder { /* Microsoft Edge */
                padding-right: 0.3rem;
                color: #adabab;
              }

            .Btns{
                display: flex;
                flex-direction: column;
                position: absolute;
                bottom: 0;
                /* gap: 15px; */
    
                button{
                    margin-top: 15px;
                    width: 350px;
                    height: 35px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFFF;
                    font-size: 18px;
                }
    
                button:nth-child(2){
                    background-color: unset;
                    width: 350px;
                    height: 35px;
                    border-radius: 30px;
                    outline: none;
                    border: 2px solid rgb(255, 255, 255, 0.3);
                    color: #FFFF;
                    font-size: 18px;
                    font-weight: 530;
                }
                
                button:hover{
                    cursor: pointer;
                }
            }
        }
    }
}


  
@media (max-width: 850px){
    div.Container {
        div.Title{
            font-size: 15px;
        }

        div.UserChoosing{
            form{
                display: flex;
                flex-direction: column;
                /* gap: 6px; */
                margin-top: 10px;
                align-items: center;
    
                input, select, textarea{
                    width: 260px;
                }

                .Btns{
                    /* gap: 10px; */
        
                    button{
                        margin-top: 10px;
                        width: 250px;
                        height: 30px;
                    }
                    
                    button:nth-child(2){
                        width: 250px;
                        height: 30px;
                    }
                }
            }
        }
    }
}